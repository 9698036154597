<template>
    <mainDiv>
        <div class="title1">Windmere Suite</div>
        <div class="roomData">
            <div class="roomImg">
                <img alt="Windmere Suite" :src="require('../../assets/thumnails/windmere-suite-23.jpg')" />
            </div>
            <div class="text2">
                <div>Escape from the cares of everyday life in this luxurious, newly remodeled guest wing.</div>
                <div class="space2"></div>
                <div class="textPrice">$210.00/night + 12% tax</div>
                <div class="textPriceSub">(All prices subject to change without notice)</div>
                <div class="textLink" @click="dispatch('windmere','rooms')">Continue Reading</div>
            </div>
        </div>
        <div class="title1">Sunrise Suite</div>
        <div class="roomData">
            <div class="roomImg">
                <img alt="Sunrise Suite" :src="require('../../assets/thumnails/Sunrise Suite-7.jpg')" />
            </div>
            <div class="text2">
                <div>Nestled in the treetops with a spectacular sunrise view, the Sunrise Suite combines elegance with a unique twist of French Chateau charm, from the tangerine walls to the delicate curves of the French Provincial style furniture.</div>
                <div class="space2"></div>
                <div class="textPrice">$180.00/night + 12% tax</div>
                <div class="textPriceSub">(All prices subject to change without notice)</div>
                <div class="textLink" @click="dispatch('sunrise','rooms')">Continue Reading</div>
            </div>
        </div>
        <div class="title1">Lilac Room</div>
        <div class="roomData">
            <div class="roomImg">      
                <img alt="Lilac Room" :src="require('../../assets/thumnails/Lilac Room-7.jpg')" />          
            </div>
            <div class="text2">
                <div>Allow your senses to be ensconced with all the delights of a lilac garden as you step into this ethereal haven.</div>
                <div class="space2"></div>
                <div class="textPrice">$160.00/night + 12% tax</div>
                <div class="textPriceSub">(All prices subject to change without notice)</div>
                <div class="textLink" @click="dispatch('lilac','rooms')">Continue Reading</div>
            </div>
        </div>
        <div class="title1">Garden Room</div>
        <div class="roomData">
            <div class="roomImg">             
                <img alt="Garden Room" :src="require('../../assets/thumnails/Garden Room-3.jpg')" />   
            </div>
            <div class="text2">
                <div>Named for the free-flowing spray of flowers cascading from the ceiling and other areas, the Garden Room provides guests with a delight of colors, warmth and stylish country charm.</div>
                <div class="space2"></div>
                <div class="textPrice">$140.00/night + 12% tax</div>
                <div class="textPriceSub">(All prices subject to change without notice)</div>
                <div class="textLink" @click="dispatch('garden','rooms')">Continue Reading</div>
            </div>
        </div>
        <div class="title1">Lighthouse Room</div>
        <div class="roomData">
            <div class="roomImg">                
                <img alt="Lighthouse Room" src="../../assets/thumnails/lighthouse-room.jpg" />
            </div>
            <div class="text2">
                <div>Escape to the sea in this ocean-themed haven that lacks only the sound of crashing waves and the taste of salt in the air.</div>
                <div class="space2"></div>
                <div class="textPrice">$120.00/night + 12% tax</div>
                <div class="textPriceSub">(All prices subject to change without notice)</div>
                <div class="textLink" @click="dispatch('lighthouse','rooms')">Continue Reading</div>
            </div>
        </div>
    </mainDiv>>
</template>

<script>
import MainDiv from '../MainDiv.vue';
export default {    
  components: {
    'mainDiv': MainDiv
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'rooms' }}));
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();   
  }
}
</script>
<style scoped>

img{
    width:100%;
    height:100%;
    object-fit:contain;
}
.roomData {
    display:flex;
    margin-bottom:30px;
    margin-top:10px;
}
.roomImg {
    width:45%;
}
.text2 {    
    padding:0 20px 0 20px;
    width:55%;
}
.textLink {
    padding-top:15px;
}
.textPrice {
    font-weight:300;
}
.textPriceSub{
    font-weight:100;
}
@media only screen and (max-width: 1152px) {
    .roomData {
        display:block;
    }
    .roomImg > img {
        width:100%;
    }
    .roomImg {
        width:100%;
    }
    .text2 {
        padding:0;
        margin-top:15px;
        width:100%;
    }
}
</style>