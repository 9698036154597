<template>
    <div class="mainDiv" :style="styleHandler('mainDiv')">
        <div class="block1">                        
            <div class="block2">
                <slot></slot>
            </div>            
        </div>
    </div>
</template>

<script>
import router from '../router.js';
export default {    
    data() {
        return {
            width: null,
            isMobile:false,
        }
    },
    methods: {
        handleResize() {
            if(!this.isMobile){
                this.width = screen.width * .6;
            } else {
                this.width = screen.width;
            }
        },
        styleHandler(name) {
            if(name==="mainDiv"){
                // if(this.isMobile){
                    return {
                        "width":this.width + "px",                        
                    }
                // } else {
                //     return {
                //         "width":this.width+"px",  
                //         "min-width":"1152px",                  
                //     }
                // }
            }
        },
        dispatch(name){
            window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: name }}));
            router.replace({path: name});
        },
        openLink(url){
            window.open(url,'_blank','noreferrer');
        }
    },
    mounted() {
        window.addEventListener('resize',this.windowSize);            
        this.windowSize();   
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        
    }
}
</script>
<style scoped>
.mainDiv {
    margin:auto;
    min-width:1152px;
}
.block1 {
    background-image: url("~@/assets/red2.jpg") !important;
    color:white;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding:4%;
}


.block2{
    background-image: url("~@/assets/lightBackground2.jpg");    
    background-size:cover;
    color:rgb(87, 87, 87);
    font-weight:200;
    font-size:18px;
    line-height:22px;
    padding:20px;
}
@media only screen and (max-width: 1152px) {
.mainDiv {
    margin:0;
    min-width:100% !important;
}
}
</style>