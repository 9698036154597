<template>
    <mainDiv2>
        <template v-slot:slot1>
            <div class="title1">Scrapbooking / Quilting Retreat</div>
            <div class="space1"></div>
            <div class="text2">
                <span class="span1">The Inn At Windmere, LLC is pleased to host scrapbooking and quilting groups in our Sunshine Room: </span>
                <span class="span2">This delightful, cheery large room will accommodate at least 12 scrapbookers or quilters. It also has a sitting area to comfortably take a snack break or watch a video with your friends. It is well lit, has lots of table space and an atmosphere conducive to a fun, productive gathering.</span>
                <div class="space2"></div>
                <div class="roomImg">
                    <div class="imgDiv1">
                        <div class="leftBtn" @click="imgClick('left')">&lt;</div>
                        <transition>
                            <img alt="Scrapbooking Retreat" class="imgCenter" :key="imgIndex" :src="getImagePath()" />
                        </transition>
                        <div class="rightBtn" @click="imgClick('right')">></div>
                    </div>            
                </div>
                <div class="space1"></div>
                <div>This package includes the full hot breakfast each morning and beverages and snacks throughout your stay but no other meals. It is $210 per person for 1-3 people, $190 each for 4-6 people, $170 each if 7 or more (+ 12% tax). This package is available throughout the days of the week and is not limited to weekends only.</div>
                <div class="space2"></div>
                <div>Extra Nights can be added for $80 per person per night (+12% tax). This will include full hot breakfast the following morning.</div>
                <div class="space1"></div>
                <div class="title2">The packages include:</div>
                <ul>
                    <div class="featureList" v-for="str in features.split('\n')" :key="str">
                        <li>{{str}}</li>
                    </div>
                </ul>
                <div class="space1"></div>
                <div class="textItalic">(To keep our prices so low, we assume women will share king beds (which are the same width as 2 twin beds) and 3-4 women will be in each of our larger rooms by adding comfortable twin beds. If guests prefer, they can spread out more for additional charge to be determined according to their needs.)</div>
            </div>
        </template>
        <template v-slot:slot2>
            <div class="title4">Retreats</div>
            <div class="textLink" @click="dispatch('scrapbooking','retreats')">Scrapbooking / Quilting</div>
            <div class="textLink" @click="dispatch('church','retreats')">Church Retreats</div>
        </template>
        <template v-slot:slot3>
            <div class="title4">Contact Us</div>
            <div class="roomText1">Have any questions or need to book a room/retreat?</div>
            <div class="textLink" @click="dispatch('contact')">Contact Us Today!</div>
        </template>
    </mainDiv2>
</template>

<script>
import MainDiv2 from '../../MainDiv2.vue';
export default {    
    components: {
        'mainDiv2': MainDiv2
    },
    data() {
        return {
            images: {},
            imgIndex: 0,
            features: "2 nights on comfortable beds with luxurious cotton sheets and towels\nBeverages and delicious snacks throughout your stay\nAmple workspace—at least a 6 foot private table per person\nOtt light for each person\nWireless Internet\nDVD/VHS players\nSpa services for additional charge (click here for more information)\nAccess to comfortable sitting rooms with fireplaces to enjoy snacks, watch a movie or relax with friends",
        }
    },
    methods:{
        importAll(r) {
            var imgs = {}     
            console.log(r);
            r.keys().forEach(key=>(imgs[key]=r(key)));
            this.images = imgs;                         
        },
        getImagePath(){            
            if(Object.keys(this.images).length > 0){
                return require('../../../assets/2023Update/Scrapbook' +  Object.keys(this.images)[this.imgIndex].substring(1));
            }
            return"";
        },
        leftClick(){
            if(this.imgIndex === 0){
                this.imgIndex = Object.keys(this.images).length -1;
            } else {
                this.imgIndex = this.imgIndex - 1;
            }
        },
        rightClick(){
            if(this.imgIndex===(Object.keys(this.images).length-1)){
                this.imgIndex = 0;
            } else {
                this.imgIndex = this.imgIndex + 1;
            }
        },
        imgClick(btn){
            clearInterval(this.timer1);
            if(btn==='right'){
                this.rightClick();
            }else {
                this.leftClick();
            }            
            this.setTimer();
        },
        setTimer() {
            this.timer1=setInterval(() => {
                this.rightClick();
            },4000);
        },
    },    
    mounted() {
        this.importAll(require.context("../../../assets/2023Update/Scrapbook/", true, /\.jpg$/));        
        console.log(this.getImagePath());
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'retreats' }}));
    },
    created() {
        this.setTimer();
    }
}
</script>
<style scoped>
.span1 {
    font-weight:300;
}
.space1 {
    height:30px;
}
.space2 {
    height:20px;
}
.textItalic {
    font-style:italic;
}
.featureList {
    font-size:17px;
}
.textLink {
    font-size:16px;
    line-height:24px;
}
.roomText1{
    margin-bottom:10px;
    font-weight:200;
}
</style>