<template>
    <mainDiv>
        <div class="title1">Scrapbooking / Quilting Retreats</div>
        <div class="row1">
            <img alt="Scrapbooking Retreat" class="img1" src="../../assets/retreats/thumbnails/scrapbooking.jpg" />
            <div class="text2">
                <div>
                    <span class="span1">The Inn At Windmere, LLC is pleased to host scrapbooking and quilting groups in our Sunshine Room: </span>
                    <span class="span2">This delightful, cheery large room will accommodate at least 12 scrapbookers or quilters. It also has a sitting area to comfortably take a snack break or watch a video with your friends. It is well lit, has lots of table space and an atmosphere conducive to a fun, productive gathering.</span>
                </div>
                <div class="textLink" @click="dispatch('scrapbooking','retreats')">Continue Reading</div>
            </div>
        </div>
        <div class="title1">Church Retreats</div>
        <div class="row1">
            <img alt="Church Retreat" class="img1" src="../../assets/retreats/thumbnails/church.jpg" />
            <div class="text2">
                <div>
                    <span class="span1">The Inn At Windmere, LLC would be thrilled to host church retreats. </span>
                    <span class="span2">Whether a men or women’s conference, a couples’ retreat or a church staff meeting, you will find the Inn conducive to learning, reflection and growth as well as relaxation.</span>
                </div>
                <div class="textLink" @click="dispatch('church','retreats')">Continue Reading</div>
            </div>
        </div>
    </mainDiv>
</template>

<script>
import router from '../../router.js';
import MainDiv from '../MainDiv.vue';
export default {    
  components: {
    'mainDiv': MainDiv
  },
  methods: {
    dispatch(name){
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'retreats' }}));
        router.replace({path: name});
    },
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'retreats' }}));
  }
}
</script>
<style scoped>
.row1 {
    display:flex;
    margin-bottom:30px;
    margin-top:10px;
}
.img1{
    width:50%;
}
.text2 {    
    padding:0 20px 0 20px;
}
.textLink {
    padding-top:15px;
}
.span1{
    font-weight:300;
}
@media only screen and (max-width: 1152px) {
    .row1 {
        display:block;
    }
    .row1 > img {
        width:100%;
    }
    .text2 {
        padding:0;
        margin-top:15px;
    }
}
</style>