<template>
    <mainDiv>
        <div class="row1">
            <span class="pTitle">Check in/Check out:</span><span class="text2"> Check-in time is 3:00 p.m. Check-out time is 12:00. If you need to make special arrangements around these times, the hosts will try to accommodate you if they know ahead of time.</span>
        </div>
        <div class="row1"><span class="pTitle">Facilities:</span><span class="text2"> In addition to settling into your private room, please feel free to enjoy a piece of Susie’s famous pound cake, play the Steinway grand piano in the great room, or curl up by one of the three fireplaces. Outdoors, you can relax on the front or back porch, savor the fresh country air and take in the pretty flower gardens with a walk, or relish a gorgeous sunset from the gazebo at the back of the property. There is also ping pong, table soccer and a home gym. Ask your host if you are interested in these. A TV with DVD/VHS player is in some rooms or available upon request. Due to insurance regulations, guests are not permitted in any of the other buildings on the property.</span></div>
        <div class="row1"><span class="pTitle">Pets:</span><span class="text2"> We are sorry we cannot accommodate pets.</span></div>
        <div class="row1"><span class="pTitle">Smoking:</span><span class="text2"> Insurance regulations do not permit smoking inside the house.</span></div>
        <div class="row1"><span class="pTitle">Host Contact:</span><span class="text2"> If your hosts are not readily available, please contact them at any time at 260-927-5116</span></div>
        <div class="row1"><span class="pTitle">Smoke Alarms:</span><span class="text2"> For your safety we have installed interconnected smoke detectors in each room and hallway. If you hear the smoke alarm, please exit the Inn promptly following the diagram on the back of your door. We will advise you of the situation.</span></div>
        <div class="row1"><span class="pTitle">Door Keys:</span><span class="text2"> You will be given a key to your private guest room. We encourage you to lock your door if you are leaving any valuables in your room. Please return this key at check-out. You will be also be given a code number to open the front door or side door with the keypad. The front door will be open during the day whenever the hosts are at home.</span></div>
        <div class="row1"><span class="pTitle">Payment:</span><span class="text2"> We accept Visa, MasterCard, Discover, American Express, personal checks or cash. Please pay after breakfast. There is 7% sales tax plus 5% visitor’s bureau (county) tax. Gift Certificates are available--contact the Inn for these.</span></div>
        <div class="row1"><span class="pTitle">Deposits:</span><span class="text2"> We require a deposit by check or credit card if you are coming for a retreat. If you are reserving a room, we need a credit card number to hold it.</span></div>
        <div class="row1"><span class="pTitle">Cancellation Policy:</span><span class="text2"> If you cancel more than 3 days before your planned arrival date, you will receive a full refund if we can rebook the room (minus a $25 processing fee). Otherwise you will be charged in full. We make exceptions for extreme circumstances.</span></div>
    </mainDiv>
</template>

<script>
import MainDiv from '../MainDiv.vue';
export default {    
  components: {
    'mainDiv': MainDiv
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'policies' }}));
  }
}
</script>
<style scoped>
.row1 {
    margin-top:20px;
}
.pTitle{
    font-weight:400;
    color:rgb(115, 115, 115);
    text-decoration: underline;
}
</style>