<template>
    <mainDiv>
        <div class="title1">Local Area Attractions</div>
        <div class="space1"></div>
        <div class="text2">{{text1}}</div>
        <div class="title2">Museums (all less than 10 minutes away)</div>
        <ul>
            <div  class="amenList">
                <li><span class="textLink" @click="openLink('http://automobilemuseum.org/')">Auburn Cord Duesenberg Museum</span></li>
                <li><span class="textLink" @click="openLink('http://www.dekalbhorsemen.com/11201.html')">Draft Animal Museum</span></li>
                <li><span class="textLink" @click="openLink('https://automotivemuseumguide.com/kruse-museums/')">Kruse Automotive & Carriage Museum</span></li>
                <li><span class="textLink" @click="openLink('http://www.natmus.org/')">National Automotive & Truck Museum</span></li>
                <li><span class="textLink" @click="openLink('https://thegarretthistoricalsociety.weebly.com/')">Garrett Historical Railroad Museum</span><span> (ten minutes away in Garrett, IN)</span></li>
                <li><span class="textLink" @click="openLink('http://www.midamericawindmillmuseum.org/')">Mid-America Windmill Museum</span></li>
                <li><span class="textLink" @click="openLink('http://www.fordv8foundation.org/')">Early Ford V-8 Museum</span></li>
                <li><span class="textLink" @click="openLink('http://www.garrettmuseumofart.org/')">Garrett Museum of Art</span></li>
            </div>
        </ul>
        <div class="title2">Things "To Do"</div>
        <ul>
            <div>
                <li><span class="textLink" @click="openLink('https://dekalbcvb.org/things-to-do/historic-walking-tour')">Walking tour of historical homes in Auburn</span></li>
                <li><span class="textLink" @click="openLink('http://www.experienceauburn.com/')">Quaint shops in downtown Auburn</span></li>
                <li><span class="textLink" @click="openLink('https://web.ncgmovies.com/#/site/?siteId=1419')">Local Theatre (NCG)</span></li>
                <li><span class="textLink" @click="openLink('http://www.mallseeker.com/fremont-outlets.aspx')">Fremont Outlet Mall</span><span> (30 minutes north off I-69)</span></li>
                <li><span class="textLink" @click="openLink('https://www.indianatrails.com/auburn-trails#:~:text=DeKalb%20County%20Trail%20is%20located,skiing%20and%20is%20wheelchair%20accessible.')">Bike trail between Auburn and Waterloo</span></li>
            </div>
        </ul>
        <div class="title2">Local Golf Courses</div>
        <ul>
            <div>
                <li><span class="textLink" @click="openLink('http://www.bridgewatergc.com/')">Bridgewater</span><span> — 10 minute drive</span></li>
                <li><span class="textLink" @click="openLink('http://www.deertrackgolf.com/')">Deer Track</span><span> — 20 minutes south</span></li>
                <li><span class="textLink" @click="openLink('http://www.noblehawk.com/')">Noble Hawk</span><span> — 20 minutes northwest</span></li>                
                <li><span class="textLink" @click="openLink('http://www.zollnergc.com/')">Zollner</span><span> — 30 minutes north on TriState University campus</span></li>
            </div>
        </ul>
        <div class="title2">Fun Places to Visit</div>
        <ul>
            <div>
                <li><span class="textLink" @click="openLink('http://www.sechlerspickles.com/')">Sechler’s Fine Pickles</span><span> (tours available)</span></li>
                <li><span class="textLink" @click="openLink('http://theolivetwist.com/')">The Olive Twist</span></li>
                <li><span class="textLink" @click="openLink('http://www.debrand.com/')">DeBrand’s Gourmet Chocolates</span><span> (tours available)</span></li>
                <li><span class="textLink" @click="openLink('http://www.satekwinery.com/')">Satek Winery</span><span> (30 miles north)</span></li>
                <li><span class="textLink" @click="openLink('http://www.countryheritagewinery.com/')">Country Heritage Winery</span></li>
                <li><span class="textLink" @click="openLink('http://www.brialivineyards.com/')">Briali Winery</span></li>
                <li><span class="textLink" @click="openLink('http://www.saudervillage.org/')">Sauder Village</span><span> (living history village 1 hour east in Archibold, OH)</span></li>
                <li><span class="textLink" @click="openLink('http://www.genestratton-porter.com/')">Gene Stratton Porter Home</span><span> (25 minutes to Rome City)</span></li>
                <li><span class="textLink" @click="openLink('http://www.botanicalconservatory.org/')">Fort Wayne Botanical Gardens</span></li>
                <li><span class="textLink" @click="openLink('http://www.fortwaynephilharmonic.com/')">Fort Wayne Philharmonic Symphony Orchestra</span></li>
                <li><span class="textLink" @click="openLink('http://www.epl.lib.in.us/')">Local genealogy centers in Auburn and Fort Wayne</span><span> (Fort Wayne has the largest collection of genealogical materials for a public library in the United States)</span></li>
                <li><span class="textLink" @click="openLink('http://www.stateparks.com/pokagon.html')">Pokagon State Park</span><span> - hike, bike swim, camp, etc.</span></li>
                <li><span class="textLink" @click="openLink('http://www.stateparks.com/chain_olakes_noble.html')">Chain ‘o’ Lakes State Park</span></li>
                <li><span class="textLink" @click="openLink('http://www.campindiana.com/bixler-lake-campground.html')">Bixler Lake Park</span><span> (beach)</span></li>
                <li><span class="textLink" @click="openLink('http://search.fortwayne.com/page/local.localDetail/lType/4/lHead/894/id/10970068/R---R-Elk-Ranch---Campground.html')">R&R Elk Ranching</span><span> (canoeing, kayaking, elk viewing)</span></li>
                <li><span class="textLink" @click="openLink('http://www.cooksbisonranch.com/')">Cook's Bison Ranch</span></li>
                <li><span class="textLink" @click="openLink('https://www.fortwayneparks.org/trails/rivergreenway.html')">Greenway walking paths in St. Joe or Fort Wayne</span></li>
                <li><span class="textLink" @click="openLink('http://www.kidszoo.org/')">Fort Wayne Children’s Zoo</span></li>
                <li><span class="textLink" @click="openLink('http://www.blackpine.org/')">Black Pine Animal Park</span><span> (see over 80 big cats, bears, bird, reptiles, etc)</span></li>
            </div>
        </ul>
        <div class="title2">Amish Communities</div>
        <ul>
            <div>
                <li><span class="textLink" @click="openLink('http://www.shipshewana.com/')">Shipshewana, Indiana</span>
                    <ul class="subList">
                        <li><span class="textLink" @click="openLink('https://www.thebluegate.com/shipshewana/blue-gate-restaurant/')">Blue Gate Restaurant</span><span> (great Amish food)</span></li>
                        <li><span class="textLink" @click="openLink('https://www.thebluegate.com/shipshewana/blue-gate-theatre/schedule/')">Blue Gate Theatre</span><span> (Quality entertainment--much from Branson, Missouri) Handcrafted wooden furniture, Unique shops, festivals</span></li>
                        <li><span class="textLink" @click="openLink('http://www.mennohof.org/')">Menno-hof Museum</span><span> (history of Mennonite and Amish)</span></li>
                        <li><span class="textLink" @click="openLink('http://www.essenhaus.com/')">Das Essenhaus Restaurant</span><span> (great Amish food)Unique Shops</span></li>
                    </ul>
                </li>
                <li><span class="textLink" @click="openLink('http://www.grabill.net/')">Grabill, Indiana</span></li>
            </div>
        </ul>
    </mainDiv>>
</template>

<script>
import MainDiv from '../MainDiv.vue';
export default {    
  components: {
    'mainDiv': MainDiv
  },
  data() {
    return {
        text1: "The Inn At Windmere, LLC, one of Indiana's finest Bed and Breakfasts and Scrapbooking Retreat Centers, is just five miles from downtown Auburn, 10 minutes from Auburn’s museums and Kruse Auction Park, and 30 minutes from Fort Wayne, Indiana. Local attractions include",
    }
  },
  methods: {
    openLink(url){
        window.open(url,'_blank','noreferrer');
    }
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'attractions' }}));
  }
}
</script>
<style scoped>
.space1 {
    height:25px;
}
.space2 {
    height:20px;
}
.subList > li {
    list-style-type:circle;
}
</style>