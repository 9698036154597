<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-defaultskin"></video>
  </div>
</template>

<script>
  import videojs from 'video.js';
  export default {
    name: 'VideoPlayer',
    props: ["options"],
    data() {
      return {
        player: null
      }
    },
    mounted() {      
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.log('onPlayerReady', this);
        this.player.volume(0.5);
        this.player.loop(true);
        this.player.muted(true);        
        this.player.play();
      })      
    },
    beforeUnmount() {
      if (this.player) {
        this.player.dispose();
      }
    },
  }
</script>