import router from './router.js';
export default {
    methods: {
        openLink(url){
            window.open(url,'_blank','noreferrer');
        },
        dispatch(name, name2='', params='{}'){
            window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: name2 !=  ''?name2:name }}));
            router.replace({name: name, params: params});
        },
        windowSize(){
            // console.log(window.innerWidth);
            if(window.innerWidth<=1152){
                this.isMobile=true;
            } else {
                this.isMobile=false;
            }
            console.log(this.isMobile);
        }
    }
}