import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';

// import iframeResize from 'iframe-resizer/js/iframeResizer';
import router from './router.js';
import store from './store/index.js';
import App from './App.vue';
import GM from './GlobalMethods.js';
import vClickOutside from 'v-click-outside'

const app = createApp(App)
// app.use(iframeResize);
app.use(createMetaManager());
app.config.productionTip = false;
app.use(router);
app.use(store);
app.mixin(GM);
app.use(vClickOutside);
app.mount('#app');

  
