<template>
    <div class="mainDiv" :style="styleHandler('mainDiv')">
        <div class="space4"></div>
        <div class="imgDiv">
            <!-- <div class="imgDiv1" >
                <div class="leftBtn" @click="imgClick('left')">&lt;</div>
                <transition>
                    <img class="imgCenter" ref="imgCenter" :key="imgIndex" :src="getImagePath()" />
                </transition>
                <div class="rightBtn" @click="imgClick('right')">></div>
            </div>             -->
            <video-player class="imgCenter" ref="imgCenter" :options="videoOptions" />
        </div>
        <div :style="{ 'height': backgroundHeight}" v-if="isMobile"></div>
        <div class="block1">
            <div class="space1"></div>
            <div class="text1">Inn At Windmere, LLC</div>
            <div class="line1"></div>
            <div class="text2">{{text2}}</div>
            <div class="textPrice">{{text3}}</div>
            <div class="buttons">
                <div class="btn1" @click="dispatch('rooms')">Rooms</div>
                <div class="btn1" @click="dispatch('retreats')">Retreats</div>
            </div>
            <div class="space2"></div>
            <div class="block2">
                <div class="b2text">
                    <span class="b2Title">{{b2text1title}}</span> {{b2text1}}                                
                </div>
                <div class="b2text">
                    <span class="b2Title">{{b2text2title}}</span> {{b2text2}}                
                </div>
                <div class="b2text">
                    <span class="b2Title">{{b2text3title}}</span> {{b2text3}}
                </div>
                <div class="b2text">
                    <span class="b2Title">{{b2text4title}}</span> {{b2text4}}
                </div>
                <div class="b2text">
                    <span class="b2Title">{{b2text5title}}</span> {{b2text5}}
                </div>     
                <div class="btn2" @click="dispatch('reservations','reservations')">Make a Reservation Now!</div>       
                <!-- <div class="awards1">
                    <div>
                        <div><a target="_blank" href="http://www.tripadvisor.com/"><img src="http://e2.tacdn.com/img2/widget/tripadvisor_logo_115x18.gif" data-src="http://e2.tacdn.com/img2/widget/tripadvisor_logo_115x18.gif" data-jchll="true" alt="TripAdvisor" class="widEXCIMG lazy-loaded" id="CDSWIDEXCLOGO"><noscript><img src="http://e2.tacdn.com/img2/widget/tripadvisor_logo_115x18.gif" alt="TripAdvisor" class="widEXCIMG" id="CDSWIDEXCLOGO"/></noscript></a></div>
                    </div>
                    <div>
                        <a target="_blank" href="http://www.tripadvisor.com/"><img src="http://static.tacdn.com/img2/t4b/Stacked_TA_logo.png" data-src="http://static.tacdn.com/img2/t4b/Stacked_TA_logo.png" data-jchll="true" alt="TripAdvisor" class="widEXCIMG lazy-loaded" id="CDSWIDEXCLOGO"><noscript><img src="http://static.tacdn.com/img2/t4b/Stacked_TA_logo.png" alt="TripAdvisor" class="widEXCIMG" id="CDSWIDEXCLOGO"/></noscript></a>
                    </div>
                    <div>
                        <a target="_blank" href="http://www.tripadvisor.com/"><img src="http://www.tripadvisor.com/img/cdsi/img2/awards/CoE2014_WidgetAsset-14348-2.png" data-src="http://www.tripadvisor.com/img/cdsi/img2/awards/CoE2014_WidgetAsset-14348-2.png" data-jchll="true" alt="TripAdvisor" class="widCOEImg lazy-loaded" id="CDSWIDCOELOGO"><noscript><img src="http://www.tripadvisor.com/img/cdsi/img2/awards/CoE2014_WidgetAsset-14348-2.png" alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO"/></noscript></a>
                    </div>
                </div>               
                <div class="space3"></div> -->
                <div class="awards1">
                    <div v-if="!isMobile">
                        <div id="TA_certificateOfExcellence415" class="TA_certificateOfExcellence"><div id="CDSWIDCOE" class="widCOE2020"> <a class="widCOEClickWrap" target="_blank" href="https://www.tripadvisor.com/Hotel_Review-g29323-d1206783-Reviews-Inn_At_Windmere-Auburn_Indiana.html" onclick="ta.cds.handleTALink(14348,this);return true;"></a> <a target="_blank" href="https://www.tripadvisor.com/Hotel_Review-g29323-d1206783-Reviews-Inn_At_Windmere-Auburn_Indiana.html" rel="nofollow"><img src="https://static.tacdn.com/img2/travelers_choice/widgets/tchotel_2022_L.png" alt="Tripadvisor" class="widCOEImg" id="CDSWIDCOELOGO"></a> </div> </div>
                    </div>                    
                    <div class="award1">
                        <a target="_blank" href="https://www.tripadvisor.com/Hotel_Review-g29323-d1206783-Reviews-Inn_At_Windmere-Auburn_Indiana.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/CoE2016_WidgetAsset-14348-2.png" data-src="https://www.tripadvisor.com/img/cdsi/img2/awards/CoE2016_WidgetAsset-14348-2.png" data-jchll="true" alt="TripAdvisor" class="widCOEImg lazy-loaded" id="CDSWIDCOELOGO"><noscript><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/CoE2016_WidgetAsset-14348-2.png" alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO"/></noscript></a>
                    </div>
                    <div v-if="!isMobile">
                        <div id="hc-ratingRatingHotel"><div id="hc-ratingRatingHotel__inner"> <img alt="Hotel Rating" id="hc-ratingRatingHotel__ribbon" src="https://media.datahc.com/ratinghotel/stellar2/ribbon.png" data-src="https://media.datahc.com/ratinghotel/stellar2/ribbon.png" data-jchll="true" class="lazy-loaded"><noscript><img alt="Hotel Rating" id="hc-ratingRatingHotel__ribbon" src="https://media.datahc.com/ratinghotel/stellar2/ribbon.png"/></noscript> <span id="hc-ratingRatingHotel__year">2020</span> <span id="hc-ratingRatingHotel__award">RECOGNITION OF EXCELLENCE</span> <div id="hc-ratingRatingHotel__hotelink"> <a id="hc-ratingRatingHotel__hotelname" target="_blank">Inn At Windmere,LLC</a> </div> <a id="hc-ratingRatingHotel__hclink" target="_blank" ><img src="https://media.datahc.com/ratinghotel/stellar2/hc_logo_whitebg.png" alt="HotelsCombined"></a> </div><div id="hc-ratingRatingHotel__rating"> <span id="hc-ratingRatingHotel__number">10</span> <span id="hc-ratingRatingHotel__pipe"></span> <span id="hc-ratingRatingHotel__ratedby">Rated by Guests</span> </div></div> <!--href="http://www.hotelscombined.com" href="https://www.hotelscombined.com/Hotel/Inn_At_Windmere.htm"-->
                    </div>
                </div>             
            </div>            
        </div>
        
    </div>
</template>

<script>
// const imgList = require.context('@/assets/front-scroll',false,/^.*\.jpg$/);
import VideoPlayer from '../VideoJS.vue';
export default {  
    components: {
        videoPlayer: VideoPlayer
    }, 
    data() {
        return {
            width: null,
            images: {},
            imgIndex: 0,             
            timer1:'',    
            text2: "Find solace from the busyness of everyday life with a stay at the Inn At Windmere, a 1910 Sears Catalogue home fully renovated and expanded into a bed and breakfast and retreat center. Full of country charm and beauty, it is located in Auburn, Indiana in the middle of forty sprawling acres of corn and bean fields.",       
            text3: "It is a mere 3 minutes from I-69 in Indiana and less than 10 minutes from numerous attractions including Auburn’s car museums.",
            b2text1title:"The spacious home lends itself to meeting the needs of a variety of guests.",
            b2text1:"The peaceful, elegant setting with private suites makes it a favorite place for a romantic getaway. With free Wi-Fi, private bathrooms, ample parking and convenient location, it is a great place for business men or women to enjoy the comforts of home as they travel. The Inn At Windmere, LLC is also a wonderful retreat center for scrapbookers, quilters, church groups or small business meetings with a large craft room and a separate large, comfortable meeting room with amenities to meet the needs of these groups.",
            b2text2title:"Our rambling bed and breakfast offers an array of options for any guest.",
            b2text2:"Besides rooms ranging from the smaller Lighthouse Room to the larger suites with separate sitting areas (all have private baths), there are numerous places indoors and out for peaceful relaxation. For scrapbooking or quilting retreats, our Sunshine Room offers ample space for at least 12 scrapbookers as well as a comfortable sitting area to watch a movie or read a book. The Great Room boasts a 1907 Steinway grand piano and will comfortably seat over 30 people—great for any type of meeting. The cozy Tea Room is a delightful place to curl up with a good book in front of the fire or enjoy chatting with friends over a cup of tea and Susie’s delicious pound cake.",
            b2text3title:"You can head outdoors to enjoy your favorite book as you sway on the wicker swing on the wrap-around porch (Susie’s favorite place on the property).",
            b2text3:"In the back of the property, you can view a stunning sunset from the gazebo, savor a s-more at the fire pit, or browse through the flower gardens",
            b2text4title:"Our mission is to meet the needs of our guests in every way.",
            b2text4:"We hope each finds the Inn At Windmere, LLC to be a peaceful haven of relaxation and refreshment whether here for a romantic getaway, retreat with friends, business travel or just passing through.",
            b2text5title:"We are certified",
            b2text5:"by the Indiana Bed and Breakfast Association and are members of DeKalb County Visitor’s Bureau and DeKalb County Chamber of Commerce.",
            isMobile:false,
            topHeight:null,
            backgroundHeight:null,
            videoOptions: {
                controls: true,
                preload: true,
                fluid: true, 
                autoplay: true,
                playsinline: true,
                loop: true,
                muted: true,
                sources: [{
                    src: require('../../assets/video/WebsiteBanner_2.mp4'),
                    type: 'video/mp4'
                }, {
                    src: require('../../assets/video/WebsiteBanner_2.webm'),
                    type: 'video/webm'
                }]
            }
        } 
    },
    methods: {
        handleResize() {
            
            if(!this.isMobile){
                this.width = screen.width * .6;
            } else {
                this.width = screen.width * 1;
            }            
        },
        styleHandler(name) {
            if(name==="mainDiv"){    
                // if(this.isMobile){
                    return {
                        "width":this.width+"px",
                        "margin":"auto",
                    } 
                // } else {            
                //     return {
                //         "width":this.width+"px",
                //         "min-width":"1152px",
                //         "margin":"auto",
                //     }
                // }
            }
        },
        importAll(r) {
            var imgs = {}     
            console.log(r);
            r.keys().forEach(key=>(imgs[key]=r(key)));
            this.images = imgs;                         
        },
        getImagePath(){            
            // console.log(Object.keys(this.images)[0].substring(1))
            if(Object.keys(this.images).length > 0){
                return require("../../assets/front-scroll" +  Object.keys(this.images)[this.imgIndex].substring(1));
            }
            return"";
        },
        leftClick(){
            if(this.imgIndex === 0){
                this.imgIndex = Object.keys(this.images).length -1;
            } else {
                this.imgIndex = this.imgIndex - 1;
            }
        },
        rightClick(){
            if(this.imgIndex===(Object.keys(this.images).length-1)){
                this.imgIndex = 0;
            } else {
                this.imgIndex = this.imgIndex + 1;
            }
            setTimeout(() => {
                
            })
        },
        imgClick(btn){
            clearInterval(this.timer1);
            if(btn==='right'){
                this.rightClick();
            }else {
                this.leftClick();
            }            
            this.setTimer();
        },
        setTimer() {
            this.timer1=setInterval(() => {
                this.rightClick();
            },4000);
        },
        
    },
    computed: {
        // backgroundHeight() {            
        //     return screen.height-this.$refs.imgCenter.getBoundingClientRect().bottom + "px";
        // }
    },
    mounted() {
        window.addEventListener('resize',this.windowSize);            
        this.windowSize();   
        this.handleResize();
        this.$refs.imgCenter.onload = (() => {
            // 55 + 30 + 10 + 160 + 5 + 75 + 20 = 350
            // console.log(this.$refs.header1);
            var h = 160;
            if(screen.width>468){
                h=80
            }
            var h1 = screen.height- 190 - h - this.$refs.imgCenter.height;
            console.log(h1);
            if(h1 > 0){
                this.backgroundHeight=h1 + "px";
            } else {
                this.backgroundHeight=0;
            }
        })
        window.addEventListener("resize", this.handleResize);
        this.importAll(require.context("../../assets/front-scroll/", true, /\.jpg$/));        
        console.log(this.getImagePath());
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'home' }}));     
        // const player = videojs('my-video', this.videoOptions, function onPlayerReady() {
        //     console.log('onPlayerReady', this)
        // });
    },
    created() {
        this.setTimer();
    }
    
}
</script>
<style scoped>
.mainDiv {
    width:100%;
    /* height:100%; */
    background-color:rgba(59, 59, 59, 0.995);
    min-width:1152px;
}

/* .leftBtn, .rightBtn, .imgCenter {    
    grid-row:1;
} */

.block1 {
    background-image: url("~@/assets/red2.jpg") !important;
    color:#fff;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding:4%;
}
.space1 {
    height:120px;
}
.space2 {
    height:155px;
}
.space3 {
    height:40px;
    
}
.text1 {
    width:100%;    
    text-align:center;
    font-family:Niconne; 
    font-size:48px;
    line-height: 56px;
    font-weight:300;
    color:#fff;
}
.line1 {
    width:20%;
    margin-left:40%;
    margin-top:25px;
    margin-bottom:25px;
    border-bottom:1px solid white;
}
.text2 {
    width:70%;
    margin-left:15%;
    font-size: 20px;
    line-height:27px;
    font-weight:300 !important;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align:center;
    margin-bottom:22px;
    color:#fff;
}
.text3 {
    width:60%;
    margin-left:20%;
    font-size:16px;
    line-height:20px;
    font-weight:200;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align:center;
}
.buttons {
    width:100%;
    display:flex;
    justify-content:center;
    color:#ffffcc;
    margin-top:60px;
}
.btn1 {
    padding:10px 15px 10px 15px;
    margin-right:4px;
    margin-left:4px;
    border:1px solid #ffffcc;
    border-radius: 2px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight:100;
    font-size:18px;
}
.btn2 {
    padding: 10px 15px 10px 15px;
    margin:auto;
    width:fit-content;
    background-color:#6d2328;
    color:#ffffcc;
    border:1px solid #6d2328;
    border-radius: 2px;
    font-weight:100;
    font-size:18px;
    filter:none;
    margin-bottom:45px;
}
.btn1:hover,.btn2:hover {
    color:#6d2328;
    background-color:#f9f9e6;
    cursor: pointer;
}

.block2{
    /* width:88%; */
    /* margin-left:calc(6% - 20px); */
    /* margin-bottom:calc(6% + 40px) !important; */
    background-image: url("~@/assets/lightBackground2.jpg");    
    background-size:cover;
    color:rgb(87, 87, 87);
    font-weight:200;
    font-size:18px;
    line-height:22px;
    padding:20px;
}
.b2text {
    margin-bottom:30px;
}
.b2Title{
    font-weight:300;
    color:rgb(63, 63, 63);
}


.awards1 {
    display:flex;
    justify-content: space-evenly !important;
    align-items:center;    
}
.awards1 > div {
    width:20%;
    display:flex;
    justify-content: center;
}

#hc-ratingRatingHotel {
    max-width: 160px !important;
    min-width: 160px !important;
    width: 160px !important;
    /* margin: 20px auto !important; */
    margin:0;
    color: black !important;
    text-align: left !important;
    letter-spacing: 2px !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__inner {
    border: 1px solid #D0D0D0 !important;
    border-bottom: none !important;
    height: 129px !important;
    padding: 8px 10px 0 !important;
    background-color: white !important;
    line-height: 13px !important;
    box-sizing: content-box !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__ribbon {
    float: left !important;
    padding-right: 10px !important;
    position: relative !important;
    top: 3px !important;
}
#hc-ratingRatingHotel img {
    box-shadow: none !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__year {
    font-size: 8px !important;
    color: #00AEE7 !important;
    display: block !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__award {
    font-size: 9px !important;
    color: #737373 !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__hotelink {
    display: table-cell !important;
    height: 67px !important;
    vertical-align: middle !important;
    width: 137px !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__hclink {
    display: inline-block !important;
    width: 100% !important;
    text-align: center !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__hotelname {
    width: 100% !important;
    display: inline-block !important;
    text-align: center !important;
    color: #00AFE1 !important;
    text-decoration: none !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 15px !important;
    font-weight: normal !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__rating {
    background-color: #00AEE5 !important;
    color: white !important;
    padding: 0px 15px !important;
    height: 40px !important;
    line-height: 40px !important;
    box-sizing: border-box !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__number {
    font-size: 20px !important;
    font-weight: bold !important;
    display: inline !important;
    width: 34px !important;
    overflow: hidden !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__pipe {
    border-left: 1px solid white !important;
    height: 15px !important;
    display: inline-block !important;
    margin: 0 4px !important;
}
#hc-ratingRatingHotel #hc-ratingRatingHotel__ratedby {
    font-size: 10px !important;
    width: 58px !important;
    display: inline-block !important;
    line-height: 10px !important;
}
#CDSWIDCOE {
    position: relative;
    font: normal 11px Trip Sans VF,Trip Sans,Arial,sans-serif;
    color: #000;
    background-color: #fff;
    overflow: hidden;
    width: 148px;
    margin: 0 10px 10px 10px;
    text-align: center;
}
#CDSWIDCOE a:hover, #CDSWIDCOE a:visited {
    border: none;
    font-weight: 400;
    color: #000;
}
#CDSWIDCOE img {
    border: none;
    display: block;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
#CDSWIDCOE .widCOEClickWrap {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;
}
.imgDiv1{
    width:100%;
    display:flex;    
    justify-content: center;
    align-items: center;
    position:relative;
    display:grid;
    grid-template-columns: 1fr;
}
.imgCenter{
    width:100%;    
    transition: all 1s ease;        
    grid-row-start:1;
    grid-column-start:1;
    position:relative;
    z-index:1;
}
.leftBtn,.rightBtn {
    color:black;
    padding:0;    
    height:80px;   
    justify-content: center;
    align-items:center;
    display:flex;
    width:50px !important;
    opacity:.5;      
    border-radius: 4px;
    background-image: url("~@/assets/red2.jpg") !important;
    z-index:80;    
    position:absolute;    
    /* bottom:50; */
}
.leftBtn:hover,.rightBtn:hover{
    opacity:1;
    cursor:pointer;
}
.leftBtn{
    left:0;
}
.rightBtn {
    margin-left:-50px;
    right:0;
}
.v-enter-active {
    opacity:0;
    z-index:10;
}
.v-leave-active {
    opacity:1;    
    z-index:1;
}

.v-enter, .v-leave-to {
    opacity:0;
}
.v-leave, .v-enter-to {
    opacity:1;
}
/* .imgIn {
    animation: imgIn .2s forwards;
}
.imgOut {
    animation: imgOut .2s forwards;
} */
/* @keyframes imgIn {
    from {
        filter:blur(20px);
        opacity:.7;
    }
    to {
        filter:none;
        opacity:1;
    }
}
@keyframes imgOut {
    from {
        filter:none;
        opacity:1;        
    }
    to {
        filter:blur(20px);
        
        opacity:.7;
    }
} */

@media only screen and (max-width: 1152px) {
    /* .leftBtn:hover,.rightBtn:hover{
        opacity:.5;
    }
    .leftBtn:active,.rightBtn:active {
        opacity:1;
    } */
    .text2 {
        width:86%;
        margin-left:7%;
    }
    .textPrice {
        margin-top:50px;
    }
    .mainDiv {
        background-color:transparent;
        min-width:100% !important;
    }
    .space4 {
        height:20px;
    }
}
</style>